/**
 * First we will load all of this project's JavaScript dependencies which
 * include Vue and Vue Resource. This gives a great starting point for
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import DeleteForm from './components/DeleteForm.vue';

Vue.component('delete-form', DeleteForm);

const app = new Vue({
  el: '#generic-app',
  data: {
  }
})
