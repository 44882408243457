<template>
  <span class="delete-form">
    <div class="alert alert-success" v-if="success">
      Successfully Deleted - Redirecting Back to List
      <a :href="this.parentPath">Back</a>
    </div>
    <div class="alert alert-danger" v-if="failure">
      An error occured - Deletion has failed.
      <a :href="this.parentPath">Back</a>
    </div>
    <button class="btn btn-danger" v-on:click="deleteCurrent()">Delete</button>
  </span>
</template>

<script>
  export default {
    data: function(){
      return {
        success: false,
        failure: false,
        parentPath: window.location.pathname.match( /^.+\//)[0]
      }
    },
    methods: {
      deleteCurrent: function() {
        const vm = this;
        if (confirm("Are you sure? Deletion cannot be undone!")) {
          let currentPath = window.location.pathname;
          this.$http.delete(currentPath)
            .then(function(response) {
              vm.success = true;
              setTimeout(function(){
                window.location = vm.parentPath
              }, 500)
            })
            .catch(function(err) {
              console.error('fail')
              vm.failure = true
            });
        }
      }
    },
    mounted: function() {
      console.log(this.parentPath)
    }
  };
</script>
